html, body {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    margin: 0px;
  }

  .container{
    height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  #root{
    height: inherit;
    width: 100%;
  }

  .App {
    overflow: hidden;
    height: inherit;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

